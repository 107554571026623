<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <!-- Header Row -->
      <b-row class="mb-2">
        <b-col cols="8"><h4>Rol Ekle</h4></b-col>
        <b-col cols="6" md="2" class="text-right">
          <b-button block @click="loadData()" variant="success">
            <feather-icon icon="RefreshCcwIcon" /> Listele
          </b-button>
        </b-col>
        <b-col cols="6" md="2" class="text-right">
          <b-button block v-b-modal.modal-prevent-closing variant="danger">
            <feather-icon icon="PlusIcon" /> Rol Ekle
          </b-button>
        </b-col>
      </b-row>
      <!-- Filters Row -->
      <b-row class="pt-2">
        <b-col cols="12" md="6">
          <b-form-group>
            <label class="d-inline-block text-sm-left mr-50">Satır</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label-align-sm="left"
            label-for="name-input"
            class="mb-0"
            label="Ara"
          >
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-row class="pt-2">
        <b-col>
          <b-table
            :striped="false"
            :bordered="false"
            :hover="true"
            :busy="show"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sticky-header="stickyHeader"
            responsive
            :filter="filter"
            class="custom-table"
          >
            <template #cell(actions)="data">
              <b-button
                @click="deleteRole(data.item)"
                size="sm"
                variant="danger"
              >
                <feather-icon icon="TrashIcon" size="16" />Delete
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
    <!-- Modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Role Adı Giriniz"
      ok-title="Ekle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      size="lg"
      size-sm
    >
      <!-- Form -->
      <form ref="form" @submit.stop.prevent="addRole">
        <b-form-group
          :state="customerState"
          label="Firma"
          label-for="name-input"
          invalid-feedback="Şirket Boş Olamaz"
        >
          <b-form-select
            v-model="customer"
            :options="customers"
            :state="customerState"
            required
          />
        </b-form-group>
        <b-form-group
          :state="nameState"
          label="Ad"
          label-for="name-input"
          invalid-feedback="Ad Boş Olamaz"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      stickyHeader: false,
      show: false,
      perPage: 8,
      pageOptions: [8, 10, 12],
      currentPage: 1,
      filter: null,

      items: [],
      customers: [],
      fields: [
        { key: "id", label: "No", sortable: true },
        { key: "customer.customerName", label: "Firma", sortable: true },
        { key: "caption", label: "Ad", sortable: true },
        { key: "actions", label: "Aksiyon", tdClass: "custom-width-actions" },
      ],
      boxOne: "",
      name: "",
      customer: 0,
      nameState: null,
      customerState: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.loadData();
    this.getCustomer();
  },
  methods: {
    async loadData() {
      this.show = true;
      this.$http
        .get("role")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.items = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async deleteRole(data) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Rolü Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxOne = value;

          if (value == true) {
            this.show = true;
            this.$http
              .delete(`role?id=${data.id}`)
              .then((response) => {
                if (response.status === 200) {
                  // If successful, update the table data by removing the deleted item
                  this.items = this.items.filter((i) => i.id !== data.id);
                  this.show = false;

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async addRole() {
      if (!this.checkFormValidity()) {
        return;
      } else {
        var sendModel = {
          caption: this.name,
          customerId: this.customer,
        };
        this.$http
          .post("role", sendModel)
          .then(async (response) => {
            if (response.status == 200) {
              this.items.push(response.data);
              this.show = false;
              this.$bvModal.hide("modal-prevent-closing");
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hata`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: error.response.data.message,
              },
            });
          });
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.customerState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.customer = null;
      this.nameState = null;
      this.customerState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.addRole();
    },
    async getCustomer() {
      this.$http
        .get("customer")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].customerName,
              };
              this.customers.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style>
.custom-width-actions {
  width: 180px; /* Set the width of the Actions column */
}
</style>
